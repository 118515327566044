import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg";
import { CardPrice, CardPriceDash, CardPriceMini } from "../../../components/card/CardValues";
import { HK5, PageHK, PageHKS, SubHK, SubHK4, SubHK5, SubHK6 } from "../../../components/heading/HeadingKalyst";
import { fase, sendFormSocket } from "../../../components/session/SessionKalyst";
import { BiMoney } from "react-icons/bi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuBox, LuCheck, LuCheckCheck, LuGlobe, LuLineChart, LuPackage, LuSend, LuTruck, LuUser2, LuUsers2 } from "react-icons/lu";
import LineChart from "../../../components/charts/LineChart" 
import BarChart from "../../../components/charts/BarChart"
import DonutChart from "../../../components/charts/DonutChart"
import { Box, HandCoins, LucideHandCoins } from 'lucide-react';
import { lineChartDataDashboard, lineChartOptionsDashboard,barChartDataCharts2,barChartOptionsCharts2, donutChartDataCharts1,donutChartOptionsCharts1} from "../../../theme/theme";
import { AlertBox } from "../../../components/heading/AlertBox";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { RiExchange2Line } from "react-icons/ri";
import { viewPrice } from "../../../components/Function";
import { CardBarra } from "../../../components/card/CardBarra";

export function PagePainelDashboard(props){  
    const [dados,setDados] = useState(null);
    const hoje = new Date(); 
    const { colorMode } = useColorMode();  
    const [reload,setReload] = useState(false);
    const [filtroL,setFiltroL]= useState({id:'0',name:'Todas Lojas'}); 
    const [filtroG,setFiltroG]= useState({id:'hoje',name:'Hoje'}); 

    function BuscarDados(reload){
        sendFormSocket({campo:'pages', modulo:'report',cmd:'dashboard',form:{reload},callback:(result)=>{ 
            // console.log(result);
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        setReload(true);
        setTimeout(()=>{
            setReload(false);
        },50)
    },[colorMode])
    useEffect(()=>{
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{   
        var dF=  {pago:0, type:'mes', pendente:0,  chargeback:0, estorno:0, nEst:0, taxa:0, med:0, nPed:0,  medPix:0, medCar:0,  pix:0, car:0, bol:0,   graf:[]};
        if(typeof dados.page[filtroG.id] !== 'undefined'){
            dF= dados.page[filtroG.id];
        } 

        const boxS={
            v0:{
                value:((dados.saldos.saldoD+dados.saldos.saldoRAS)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                info:((dados.saldos.saldoP)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})
            },
            v1:{
                value:(dF.pago*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                info:filtroL.name
            },
            v2:{
                value:viewPrice(dF.estorno),
                info:filtroL.name  
            },
            v3:{
                value: viewPrice((dF.pago/dF.nPed).toFixed(0)),
                info:(dF.nPed)+' pedidos',
                info2:((dados.saldos.vendasO)*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2})
            } 
        }  
        //transações"Vendas", "Estornos", "Chargback"
        let barVolume = [
            {
              name: "Vendas",
              porcent:  0,
              data: [0],
            },
            {
              name: "Estornos",
              porcent:  0,
              data: [0],
            },
            {
              name: "Chargback",
              porcent:  0,
              data: [0],
            }
        ];


        try {
            var total = dados.saldos.vendas+dados.saldos.estorno+dados.saldos.chargeback;
            barVolume[0].data[0]= dados.saldos.vendas; 
            barVolume[1].data[0]= dados.saldos.estorno;
            barVolume[2].data[0]= dados.saldos.chargeback;

            barVolume[0].porcent[0]= total/dados.saldos.vendas*100; 
            barVolume[1].porcent[0]= total/dados.saldos.estorno*100;
            barVolume[2].porcent[0]= total/dados.saldos.chargeback;
        } catch (error) {
            
        }
        //rastreios
        let barRastreios = [
            {
              name: "Enviados",
              data: [0],
            },
            {
              name: "Pendente",
              data: [0],
            },
            {
              name: "Digital",
              data: [0],
            }
        ];
        try {
            barRastreios[0].data[0]=dados.saldos.rastF;
            barRastreios[1].data[0]=dados.saldos.rastP;
            barRastreios[2].data[0]=dados.saldos.rastD;
        } catch (error) {
            
        }
        //pedidos pagos
        let donutP = [1, 1, 1];
        // try {  
            if(dF.pix>0 || dF.pix>0 || dF.bol>0){
                donutP = [dF.pix, dF.car,dF.bol];
            }  
        // } catch (error) {
        //    // console.log(error.message);
        // } 
        //grafico
        let grafico = {"Jan":null, "Fev":null, "Mar":null, "Abr":null, "Mai":null, "Jun":null, "Jul":null, "Ago":null, "Set":null, "Out":null, "Nov":null, "Dez":null};
        try { 
            grafico = {};
            // console.log(filtroG)
            switch (filtroG.id) {
                case 'hoje':case 'ontem':
                    Object.keys(dF.graf).forEach((key)=>{
                        grafico[key+'h'] = dF.graf[key].g; 
                    }) 
                break; 
                case 'menos90':case 'menos180':
                    Object.keys(dF.graf).reverse().forEach((key)=>{
                        grafico[key.split('-')[1]+'/'+key.split('-')[0]] = dF.graf[key].g; 
                    }) 
                break;
                default:
                    Object.keys(dF.graf).reverse().forEach((key)=>{
                        grafico[key.split('-')[2]+'/'+key.split('-')[1]] = dF.graf[key].g; 
                    }) 
                break;
            }
                                                
        } catch (error) {
            
        }   
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'16px'}} align={'flex-start'} justify='flex-start'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}}   margin = '0 auto'   >
               <Flex width={'100%'} direction={{base:'column',md:'row'}} gap={'10px'}>
                    <Stack width={'100%'} gap={0} direction={{base:'row',md:'column'}}  >
                        <SubHK4  h='16px' p='0' textAlign='left'>Análise Geral
                        </SubHK4> 
                        <SubHK6   h='16px' p='0' textAlign={{base:'right',md:'left'}}>Resumo de <Text as={'span'}  color='darkTema1.300' >{filtroL.name}</Text>
                        </SubHK6> 
                    </Stack>
                    
                    <Flex gap={'20px'}>
                        <FilterKalyst w={{base:'50%',md:'140px'}} title='Lojas:' value={filtroL.id} dados={[ 
                            {id:'0',name:'Todas'} 
                        ]} onchange={(e)=>{ 
                            if(e!=null && typeof e.id!=='undefined'){
                                setFiltroG(e)
                                setReload({});
                                setTimeout(()=>{setReload(null)})
                            //     const {page:pageS}= getFilter(session) 
                            //     if(e.id.split('_').length==2){
                            //         pageS.t=e.id.split('_')[0];
                            //         pageS.o=e.id.split('_')[1]; 
                            //     }
                            //     setFilterPage(session,pageS); 
                            //     tempLoja = null; 
                            //     setTimeout(()=>{
                            //         BuscarDados(true); 
                            //     },400)
                            } 
                        }} />
                        <FilterKalyst w={{base:'50%',md:'140px'}}  title='Filtrar de:' value={filtroG.id} dados={[
                            
                            {id:'hoje',name:'Hoje'},
                            {id:'ontem',name:'Ontem'},
                            {id:'menos7',name:'Últimos 7 dias'},
                            {id:'menos15',name:'Últimos 15 dias'},
                            {id:'menos30',name:'Últimos 30 dias'},
                            {id:'menos90',name:'Últimos 90 dias'},
                            {id:'menos180',name:'Últimos 180 dias'} ,
                            {id:'anual',name:'Anual'},
                            {id:'geral',name:'Geral'},
                        ]} onchange={(e)=>{ 
                            if(e!=null && typeof e.id!=='undefined'){
                                setFiltroG(e)
                                setReload({});
                                setTimeout(()=>{setReload(null)})
                            //     const {page:pageS}= getFilter(session) 
                            //     if(e.id.split('_').length==2){
                            //         pageS.t=e.id.split('_')[0];
                            //         pageS.o=e.id.split('_')[1]; 
                            //     }
                            //     setFilterPage(session,pageS); 
                            //     tempLoja = null; 
                            //     setTimeout(()=>{
                            //         BuscarDados(true); 
                            //     },400)
                            } 
                        }} />
                    </Flex>
                   
                </Flex>  
                {fase==1?
                    <AlertBox title='Documentos Pendentes' desc='Valide seus documentos para ativar o acesso completo da sua conta!' url='/document'/>
                    :null
                }
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                >
                    <GridItem colSpan={2}  >
                        <CardPriceDash icon={LucideHandCoins} title={'Saldo'} tempo={'Disponível'} py='10px'   value={boxS.v0.value}
                            info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxS.v0.info}</Text>} 
                            infoM={'Saldo a Liberar'}
                        />
                    </GridItem>   
                    <GridItem colSpan={2}  >
                        <CardPriceDash icon={BiMoney} title={'Vendas'} tempo={filtroG.name} py='10px'   value={boxS.v1.value} 
                            info={<Text color={'textLight.300'} _dark={{color:'textDark.300'}} fontWeight='bold'>{boxS.v1.info}</Text>}  
                        />
                    </GridItem>  
                    <GridItem colSpan={2}  >
                        <CardPriceDash icon={RiExchange2Line} title={'Estornos'} tempo={filtroG.name} py='10px'   value={boxS.v2.value} 
                            info={<Text color={'textLight.300'} _dark={{color:'textDark.300'}} fontWeight='bold'>{boxS.v2.info}</Text>}  
                        />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPriceDash icon={FaArrowTrendUp} title={'Ticket Médio'} tempo={filtroG.name} py='10px'   value={boxS.v3.value} 
                            info={<Text color={'textLight.300'} _dark={{color:'textDark.300'}} fontWeight='bold'>{boxS.v3.info}</Text>}  
                        />
                    </GridItem>   
                </Grid> 
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                >
                    <GridItem colSpan={{base:8,lg:6}}  >
                        <Stack flex='0'  gap={'16px'} >
                            <Stack w={'full'}   bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} >
                                <Flex p={'16px'} pb='0px'>
                                    <SubHK icon={LuLineChart} title={'Volumetria de Transações'}/> 
                                </Flex>
                                <Stack w='100%' minH='362px'>
                                    {reload?null:
                                        <LineChart
                                            chartData={[
                                                {
                                                  name: "Transações",
                                                  data:  Object.values(grafico).map((v)=>{return (v*0.01).toFixed(2)}),
                                                } 
                                              ]}
                                            chartOptions={lineChartOptionsDashboard(colorMode,
                                                 Object.keys(grafico)
                                            )}
                                        />
                                    }
                                    
                                </Stack> 
                            </Stack>
                            <Flex w={'full'} flexDirection={{base:'column',md:'row'}}  gap={'16px'}> 
                                <Stack w={'100%'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} pb='16px'>
                                    <Flex p={'16px'} pb='0px'>
                                        <SubHK icon={LuBox} title={'Análise de Rastreio'}/>
                                    </Flex> 
                                    <Flex w={'calc(100% - 16px)'} gap={'16px'}  p={'16px'} > 
                                        <CardBarra sigla='' icon={LuPackage} title='Físico' value={(dados.saldos.rastF+dados.saldos.rastP)+' pedidos'} porcent={(dados.saldos.rastF+dados.saldos.rastP)/dados.saldos.rastD*100}/>
                                        <CardBarra sigla='' icon={LuGlobe} title='Digital' value={dados.saldos.rastD+' pedidos'} porcent={(dados.saldos.rastD+dados.saldos.rastP)/dados.saldos.rastF*100}/>  
                                    </Flex>
                                    <Flex w={'calc(100% - 16px)'} gap={'16px'}  p={'16px'} pt='0px'> 
                                        <CardBarra sigla='' icon={LuCheckCheck} title='Com Rastreio' value={dados.saldos.rastF+' pedidos'} porcent={(dados.saldos.rastF)/dados.saldos.rastP}/>
                                        <CardBarra sigla='' icon={LuTruck} title='Sem Rastreio' value={dados.saldos.rastP+' pedidos'} porcent={100-(dados.saldos.rastF)/dados.saldos.rastP}/>  
                                    </Flex>
                                </Stack> 
                               
                            </Flex>
                             
                        </Stack>
                    </GridItem>
                    <GridItem colSpan={{base:8,lg:2}}  >
                        <Stack w={'full'} gap={'16px'}>
                            <Stack minH={'200px'} w={'full'} p={'16px'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'}>
                                    <SubHK icon={HandCoins} title={'Pedidos Pagos'}/> 
                                    <Stack h={'200px'} w='full'>
                                    {reload?null:<DonutChart
                                            chartData={donutP}
                                            chartOptions={donutChartOptionsCharts1}
                                        /> }
                                    </Stack>
                                    
                                    <Flex w={'full'}  align='center' justify={'space-between'}>
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
                                            PIX
                                        </Text>
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
                                            R$ {viewPrice(dF.pix)}
                                        </Text>
                                    </Flex> 
                                    <Progress colorScheme='pix'  h='12px'   bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}} borderRadius='8px'  value={((dF.pix*100)/dF.pago)} />
                                    <Flex w={'full'}  align='center' justify={'space-between'} >
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
                                            Cartão
                                        </Text>
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
                                            R$ {viewPrice(dF.car)}
                                        </Text>
                                    </Flex> 
                                    <Progress colorScheme='card' h='12px'  bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}} borderRadius='8px'  value={((dF.car*100)/dF.pago)} />
                                    <Flex w={'full'}  align='center' justify={'space-between'}>
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold'>
                                            Boleto
                                        </Text>
                                        <Text fontSize={{ base: 'sm' }} color='textLight.100' _dark={{color:'textDark.200'}} fontWeight='bold' >
                                            R$ {viewPrice(dF.bol)}
                                        </Text>
                                    </Flex> 
                                    <Progress colorScheme='boleto' h='12px'  bg='lightTema1.400' _dark={{bg:'bgMenuDark.200'}}  borderRadius='8px'  value={((dF.bol*100)/dF.pago)} />
                            
                            </Stack>
                            <Stack w={'100%'}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius={'8px'} pb='16px' gap={'0px'}>
                                <Flex p={'16px'} pb='0px'>
                                    <SubHK icon={LuLineChart} title={'Volumetria de Transações'}/>
                                </Flex>
                                <Stack w={'calc(100% - 16px)'} h={'190px'} > 
                                {reload?null:<BarChart
                                        chartData={barVolume}
                                        chartOptions={barChartOptionsCharts2(colorMode)}
                                    />}
                                </Stack>
                            </Stack>
                        </Stack>
                        
                    </GridItem>                 
                </Grid> 
            </Stack> 
        )
    }
    
}